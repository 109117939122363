body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.editor-wrapper {
    background-color: #efefef;
    flex-direction: column;
    flex-grow: 1;
    width: '100%';
}

.editor-wrapper.diagnosis {
    flex-direction: column;
    flex-grow: 1;
    width: '100%';
    border: 24px solid #f3d893;
}

.custom-editor {
    background-color: #efefef;
}

.custom-editor.diagnosis {
    border: 24px solid #f3d893;
}

.custom-editor .socket {
    background-color: #aaaaaa;
    transition: all 0.3s;
}

.custom-editor .node {
    border: 1px solid #a1a1a1;
    background-color: #ffffff;
    transition: all 0.3s;
    width: 250px;
}

.custom-editor .node.focus {
    opacity: 0.4;
}

.custom-editor .node:hover {
    border: 1px solid #a1a1a1;
    background-color: #ecfbfd;
}

.custom-editor .node.selected {
    background-color: #ffffff;
    border: 1px solid #04b7ef;
    box-shadow: 0 0 10px #8a8a8a;
}

.custom-editor .node.selected.focus {
    opacity: 0.4;
}

.custom-editor .node .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    color: #ffffff;
    background-color: #a1a1a1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 700;
}

.custom-editor .node .title .id {
    font-size: 14px;
}

.custom-editor .node.selected .title {
    background-color: #04b7ef;
}

.custom-editor .node.selected .title .id {
    font-size: 14px;
}

.custom-editor .node .control {
    display: flex;
}

.custom-editor .node .control .text-control {
    flex-grow: 1;
    border: none;
    background-color: #e8e8e8;
    padding: 8px;
    border-radius: 5px;
    min-height: 36px;
    font-family: inherit;
    font-size: 14px;
    resize: none;
    height: fit-content;
}

.custom-editor .node .control .checkbox-control {
    display: flex;
    align-items: center;
}

.custom-editor .node .control .checkbox-control .checkbox-control-label {
    font-size: 12px;
    color: #666666;
    margin-left: 8px;
}

.custom-editor .node .control .number-control {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
}

.custom-editor .node .control .number-control .number-control-label {
    font-size: 12px;
    color: #666666;
    margin-left: 4px;
    margin-right: 8px;
    flex-grow: 2;
}

.custom-editor .node .control .number-control .number-control-input {
    flex-grow: 1;
    width: 80px;
}

.custom-editor .node .control .question-id-control {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
}

.custom-editor .node .control .question-id-control .question-id-control-label {
    font-size: 12px;
    color: #666666;
    margin-left: 4px;
    margin-right: 8px;
    flex-grow: 2;
}

.custom-editor .node .control .question-id-control .question-id-control-input {
    flex-grow: 1;
    width: 80px;
}

.custom-editor .node .control .add-option-control {
    display: flex;
    flex-grow: 1;
}

.custom-editor .node .control .remove-option-control {
    display: flex;
    flex-grow: 1;
}

.custom-editor .node .inline-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.custom-editor .node .inline-wrapper .control {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-right: 0;
}

.custom-editor .node .inline-wrapper .output {
    flex: none;
}

.custom-editor .node .control input {
    border: none;
    background-color: #e8e8e8;
    padding: 8px;
    border-radius: 5px;
    flex-grow: 1;
}

.custom-editor .node .control textarea {
    border: none;
    background-color: #e8e8e8;
    padding: 8px;
    border-radius: 5px;
    flex-grow: 1;
}

.custom-editor .node .control button {
    border: 1px solid #aaaaaa;
    background-color: #ffffff;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-editor .node .control button:hover {
    background-color: #aaaaaa;
}

.custom-editor .node .control .add-option-control-button {
    flex-grow: 1;
    text-align: center;
}

.custom-editor .node .control .remove-option-control-button {
    flex-grow: 1;
    text-align: center;
}

.custom-editor .node .control .add-option-control-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.custom-editor .node .control .remove-option-control-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.custom-editor .node .control .option-control {
    display: inline-flex;
    flex-grow: 1;
}

.custom-editor .node .control .option-control-button {
    margin-right: 8px;
}

.custom-editor .node .control .option-control-icon {
    width: 18px;
    height: 18px;
}
